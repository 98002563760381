import { useState, useEffect } from 'react'
import {
  Banner,
  BannerParagraph,
  BannerAction,
} from '@dfds-ui/react-components'
import { track } from '@dfds-frontend/tracking'
import { useQuery, gql } from '@apollo/client'
import { useSiteSettingsContext } from '../SiteSettings/SiteSettingsContext'
import { css } from '@emotion/react'
import {
  useBusinessUnitContext,
  BusinessUnitTypes,
} from '../BusinessUnitContext'
import { useLocaleContext } from '../LocaleContext'
import { useLocalizedLinkTrackingData } from '../EntryLink/LocalizedLink'

const TICKERQUERY = gql`
  query TickerQuery($id: String!, $locale: String) {
    ticker(id: $id, locale: $locale) {
      enabled
      seriousTicker
      tickerMessage
      link {
        linkText
        ctaButtonTracking
        ctaButtonName
        ctaButtonStage
        destination {
          ... on UrlSlug {
            slug
          }
        }
      }
    }
  }
`

const getTickerId = (businesUnit, siteSettings) => {
  switch (businesUnit) {
    case BusinessUnitTypes.PASSENGER:
      return siteSettings?.passengerFerriesTicker?.sys.id
    case BusinessUnitTypes.LOGISTICS:
      return siteSettings?.logisticsSolutionsTicker?.sys.id
    case BusinessUnitTypes.FREIGHT:
      return siteSettings?.freightShippingTicker?.sys.id
    case BusinessUnitTypes.GROUP:
      return siteSettings?.groupTicker?.sys.id
    default:
      return null
  }
}

const Ticker = () => {
  const currentBusinessUnit = useBusinessUnitContext()
  const siteSettings = useSiteSettingsContext()
  const { locale, localeLower } = useLocaleContext()
  const { data } = useQuery(TICKERQUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: getTickerId(currentBusinessUnit, siteSettings) || null,
      locale: locale || 'en',
    },
  })
  const [getHandleClose, setHandleClose] = useState(
    typeof sessionStorage !== 'undefined'
      ? sessionStorage.tickerBannerNotVisible
      : false,
  )
  const { getLocalizedLinkTrackingData } = useLocalizedLinkTrackingData()

  useEffect(() => {
    if (getHandleClose === true) {
      sessionStorage.tickerBannerNotVisible = getHandleClose
    }
  }, [getHandleClose])

  return (
    data?.ticker.enabled === true &&
    !getHandleClose && (
      <Banner
        css={css`
          ${data.ticker.seriousTicker &&
          `
            position: sticky;
            top: 0;
            z-index: 110;
          `};
        `}
        divider={!data.ticker.seriousTicker}
        sticky={false}
        variant={data.ticker.seriousTicker && 'highEmphasis'}
        intent={data.ticker.seriousTicker ? 'critical' : 'info'}
        onRequestClose={() => setHandleClose(true)}
        actions={
          data.ticker?.link
            ? [
                <BannerAction
                  key="primary-action"
                  variation="primary"
                  onClick={() => {
                    window.location.href = `/${localeLower}/${data.ticker.link.destination.slug}`
                    data.ticker.link?.ctaButtonTracking &&
                      track(getLocalizedLinkTrackingData(data.ticker.link))
                  }}
                >
                  {data.ticker.link.linkText}
                </BannerAction>,
              ]
            : null
        }
      >
        <BannerParagraph>{data.ticker.tickerMessage}</BannerParagraph>
      </Banner>
    )
  )
}

export default Ticker
